import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/dark";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Feature from "@onnit-js/ui/components/module/Feature";
import Box from "@onnit-js/ui/components/box/Box";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import Button from "@onnit-js/ui/components/button/Button";
import { List, ListItem, Text } from "@onnit-js/ui/components/text";
import { Img } from "@onnit-js/ui/components/image";
import Embed from "@onnit-js/ui/components/video/Embed";

const certifications = [
    {
        slug: "certifications-foundations",
        title: "Foundations",
        description:
            "The perfect next step to expand your Onnit fitness journey. Discover the core principles of our fitness philosophy, and learn how to maximize longevity and performance for life.",
        includes: [],
    },
];

export default function Page() {
    const onnitContext = useOnnitContext();

    if (!onnitContext) {
        return null;
    }

    const IMG_URL = `${onnitContext.url.cdn.static_images}/landers/certifications/list/`;
    const LOGO_URL = `${onnitContext.url.cdn.static_images}/interface/`;
    const IMG_PARAMS = "?q=40&fm=pjpg&auto=compress,format";

    const foundationsGreen = "#00AC69";
    const darkGreen = "#37584B";

    return (
        <ThemeProvider theme={theme}>
            <Box bg="black">
                <GlobalStyle />
                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1 / -1", "4 / span 6"]} mt="13%">
                        <Box maxWidth={["340px", "340px", "640px"]} textAlign="center" style={{ margin: "0 auto" }}>
                            <Img
                                width={613}
                                height={54}
                                src={`${LOGO_URL}onnit-certifications-logo.svg`}
                                alt="Onnit Certifications"
                            />
                        </Box>
                    </Box>
                </TwelveColumn>
                <Box display="flex" flexDirection="column" alignItems="center" pt={[8, 8, 10]} pb={[6, 6, 8]}>
                    <Box maxWidth="720px" px={4}>
                        <Text as="h1" typeStyle="copyHero01" textAlign="center" mb={[4, 4, 6]}>
                            Get Onnit Certified
                        </Text>
                        <Text as="p" typeStyle="copy02" textAlign="center">
                            Onnit provides fitness education designed specifically to enhance both your personal journey
                            and professional development. Discover the secrets of unconventional training, and the
                            mindset that expands it, to reach your full potential—as a recreational athlete, or a
                            fitness coach. Online courses offer the ability to learn the Onnit fitness system on your
                            own time and become an Onnit Certified Trainer.
                        </Text>
                    </Box>
                </Box>

                <div className="hide-ie">
                    <TwelveColumn mb={[6, 6, 8]}>
                        <Box gridColumn={["1 / -1", "1/-1", "3 / span 8"]} px={4} mb={[4, 6]}>
                            <Embed
                                title="Onnit Certifications Product video"
                                src="https://player.vimeo.com/video/609911915"
                            />
                        </Box>
                    </TwelveColumn>

                    <Box bg="white">
                        <TwelveColumn py={5} my={3}>
                            <Box gridColumn={["1 / -1", "1/-1", "2 / span 8"]} px={4} py={4}>
                                <Text as="h2" typeStyle="copyDisplay02" color={darkGreen}>
                                    Benefits
                                </Text>
                                <List ml={[2]} pl={[4]}>
                                    {[
                                        "Teaching and programming strategies for longevity and performance.",
                                        "In-depth courses for mastering unconventional training tools, including the kettlebell, steel&nbsp;club, and steel&nbsp;mace.",
                                        "Online option—learn from anywhere, at your own pace.",
                                        "Hundreds of videos, lectures, and tutorials.",
                                        "Earn your Onnit training certification and become a top-level coach.",
                                    ].map((text, index) => (
                                        <ListItem
                                            /* eslint-disable-next-line react/no-array-index-key */
                                            key={index}
                                            color="black"
                                            my={[3, 6]}
                                            typeStyle="copy02"
                                            dangerouslySetInnerHTML={{ __html: text }}
                                        />
                                    ))}
                                </List>
                            </Box>
                        </TwelveColumn>
                    </Box>

                    <TwelveColumn my={[4, 4, 6]}>
                        <Box gridColumn={["1 / -1", "1/ -1", "3 / span 8"]} px={4} py={[4, 5]}>
                            <Text
                                as="h2"
                                typeStyle="copyDisplay02"
                                textAlign="center"
                                mb={[4, 4, 6]}
                                color={foundationsGreen}
                            >
                                Introducing Onnit Certifications:
                                <br />
                                Foundations
                            </Text>
                            <Text as="p" typeStyle="copy02" textAlign="center">
                                Onnit Certifications currently offers an online, home-study option you can use to learn
                                the ins and outs of Onnit’s training philosophy. Our flagship certification:
                                Foundations. Whether you’re a regular guy or gal who wants to get the most out of your
                                own workouts, or a trainer/coach who wants to create better workouts and training
                                experiences for clients, Onnit’s fitness education can show you the most effective ways
                                to train and save you years of trial and error.
                            </Text>
                        </Box>
                    </TwelveColumn>

                    {certifications.map(({ slug, title, description, includes }) => (
                        // eslint-disable-next-line react/jsx-key
                        <Box pb={[4, 4, 6]}>
                            <Feature
                                key={slug}
                                px={4}
                                py={[6, 6, 8]}
                                href={`/${slug}`}
                                heading={(
                                    <Text typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]}>
                                        {title}
                                    </Text>
                                )}
                                image={(
                                    <Img
                                        alt={`Onnit Certifications - ${title}`}
                                        src={`${IMG_URL}/${slug}.jpg${IMG_PARAMS}`}
                                        width={2368}
                                        height={1040}
                                    />
                                )}
                                cta={(
                                    <Button el="a" href={`/${slug}`} width={1}>
                                        Start Now
                                    </Button>
                                )}
                                content={(
                                    <Text as="p" typeStyle="body">
                                        {description}
                                    </Text>
                                )}
                            />
                            {includes.length > 0 && (
                                <TwelveColumn px={4}>
                                    <Box gridColumn={["1/ -1", "1 / -1", "2 / span 7"]}>
                                        <Text typeStyle="copyDisplay02" color={foundationsGreen}>
                                            {title} Includes
                                        </Text>
                                        <List ml={[2]} pl={[4]}>
                                            {includes.map((text, index) => (
                                                <ListItem
                                                    /* eslint-disable-next-line react/no-array-index-key */
                                                    key={index}
                                                    my={[3, 6]}
                                                    color="white"
                                                    typeStyle="copy02"
                                                    dangerouslySetInnerHTML={{ __html: text }}
                                                />
                                            ))}
                                        </List>
                                    </Box>
                                </TwelveColumn>
                            )}
                        </Box>
                    ))}
                </div>
            </Box>
        </ThemeProvider>
    );
}
